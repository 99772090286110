<template>
  <div class="container">
    <h2 class="titlePage">Page not found</h2>
  </div>
</template>

<script>
  export default {
    name: "E404"
  }
</script>

<style scoped>

</style>
